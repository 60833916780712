<script setup lang="ts">
/* Props */

const props = defineProps<{
  redirectUrl: string;
}>();

/* Pinia stores */

/**
 * The auth Pinia store.
 */
const auth = useAuthStore();

/**
 * The account Pinia store.
 */
const accountStore = useAccountStore();

/* Component variables */

/**
 * The switch account dialog.
 */
const dialog: Ref<HTMLDialogElement | null> = ref(null);

/**
 * Is the accounts list loading.
 */
const isLoading: Ref<boolean> = ref(true);

/* Computed variables */

/**
 * The accounts returned by the API.
 */
const accounts: ComputedRef<Account[]> = computed(
  (): Account[] => accountStore.accounts,
);

/* Component methods */

/**
 * Show the switch account dialog.
 */
const showDialog = (): void => {
  (dialog.value as HTMLDialogElement).showModal();
};

/* Component events */

onMounted(async (): Promise<void> => {
  await accountStore.index();

  isLoading.value = false;
});

/* Component methods */

/**
 * Switch the user's account.
 */
const switchAccount = async (accountId: string): Promise<void> => {
  // Send the auth check with the new account ID header
  await auth.switchAccount(accountId);

  // Redirect to the redirect URL
  window.location.href = props.redirectUrl;
};

/* Exposes */

defineExpose({
  // Expose the show dialog method to parent components
  showDialog,
});
</script>

<template>
  <dialog ref="dialog" class="dialog-small">
    <div class="dialog-container">
      <header>
        <h1 class="dialog-heading">Switch account</h1>
      </header>

      <template v-if="isLoading" />

      <div v-else class="dialog-option-button-group">
        <button
          type="button"
          v-for="account in accounts"
          :key="account.id"
          class="button button-tertiary-secondary button-align-start"
          @click="switchAccount(account.id)"
        >
          {{ account.name }}
        </button>
      </div>

      <footer class="dialog-footer">
        <button
          type="button"
          class="button button-tertiary-secondary"
          @click="(dialog as HTMLDialogElement).close()"
        >
          Cancel
        </button>
      </footer>
    </div>
  </dialog>
</template>
